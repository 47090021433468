import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import type { NextPage } from 'next'

// import t from '../../hooks/useFormatMessage'
import useFormatMessage from '../../hooks/useFormatMessage'

import Form from '../../components/login/form'
// import Register from '../../components/login/register'

import styles from './login.module.css'

const Login: NextPage = () => {
  const router = useRouter()
  const [error, setError] = useState('')

  const t = useFormatMessage()

  const logIn = async (email: string, password: string) => {
    try {
      setError('')
      const user = await Parse.User.logIn(email, password)
      if (user.get('emailVerified')) {
        // router.reload()
        // router.push(`/company/[apjngCVEV6]/dashboard`)
        // console.log(user.get('company'))
        router
          .push(`/company/${user.get('company').id}/dashboard`)
          .then(router.reload)
      } else {
        await Parse.User.logOut()
        setError(t('login_email_unverified'))
      }
    } catch (e: any) {
      // setError(e.message)
      setError(t('login_invalid_email_password'))
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    logIn(e.target[0].value, e.target[1].value)
  }

  useEffect(() => {
    if (Parse.User.current()) {
      router.push('/')
    }
  })
  return (
    <div className={styles.loginWrapper}>
      <h4>Login</h4>
      <Form onSubmit={onSubmit} />
      <br />
      <br />
      {error && <p className={styles.error}>{error}</p>}
      {/* <Register /> */}
    </div>
  )
}

export default Login
