// import t from '../../../hooks/useFormatMessage'
import useFormatMessage from '../../../hooks/useFormatMessage'

import styles from './form.module.css'

import Mail from '../../../assets/icons/mail'
import Lock from '../../../assets/icons/lock'
import Login from '../../../assets/icons/login'

interface Props {
  onSubmit: (e: any) => void
}

const Form: React.FC<Props> = ({ onSubmit }) => {
  const t = useFormatMessage()

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={onSubmit}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputIcon}>
            <Mail />
          </div>
          <input id="email" type="email" placeholder="E-mail" required />
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputIcon}>
            <Lock />
          </div>
          <input
            id="password"
            type="password"
            placeholder={t('login_password')}
            required
          />
        </div>
        <button id="submit" type="submit">
          <div className={styles.icon}>
            <Login />
          </div>
          Login
        </button>
      </form>
    </div>
  )
}

export default Form
